exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-ant-button-mdx": () => import("./../../../src/pages/ant/button.mdx" /* webpackChunkName: "component---src-pages-ant-button-mdx" */),
  "component---src-pages-ant-text-mdx": () => import("./../../../src/pages/ant/text.mdx" /* webpackChunkName: "component---src-pages-ant-text-mdx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mui-button-mdx": () => import("./../../../src/pages/mui/button.mdx" /* webpackChunkName: "component---src-pages-mui-button-mdx" */),
  "component---src-pages-mui-card-mdx": () => import("./../../../src/pages/mui/card.mdx" /* webpackChunkName: "component---src-pages-mui-card-mdx" */),
  "component---src-pages-mui-rarity-mdx": () => import("./../../../src/pages/mui/rarity.mdx" /* webpackChunkName: "component---src-pages-mui-rarity-mdx" */),
  "component---src-pages-mui-text-mdx": () => import("./../../../src/pages/mui/text.mdx" /* webpackChunkName: "component---src-pages-mui-text-mdx" */)
}

